<template>
  <div
    id="contents"
    :class="{ collection: tabIndex == 0 || tabIndex == 1, bond: tabIndex == 2 }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToCollectionPage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 0"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">조회&수정</a>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">등록</a>
          </li>
        </ul>
      </div>
      <collection-modification-form
        v-if="tabIndex == 0"
      ></collection-modification-form>
      <collection-new-form v-if="tabIndex == 1"></collection-new-form>
    </div>
  </div>
</template>

<script>
import CollectionModificationForm from '@/views/forms/Product/Collection/CollectionModificationForm.vue';
import CollectionNewForm from '@/views/forms/Product/Collection/CollectionNewForm.vue';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    CollectionModificationForm,
    CollectionNewForm,

    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCollectionPage',
      tabIndex: 'getOpenTabIndexFromCollectionPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToCollectionPage',
      SetOpenTabIndex: 'setOpenTabIndexToCollectionPage',
    }),
  },
  created() {
    // 현재 페이지로 진입하면
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitCollectionPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
